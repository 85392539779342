<template>
	<Toast :baseZIndex="1100" />
	<div class="p-grid" >
		<div class="p-col-12 p-md-6">
			<div class="card">
				<h5 style="margin-left: 10px;">获取客服帮助</h5>
				<img style="margin-left: 10px;border-radius: 10px;" src="../../../public/assets/layout/images/helper.jpg" width="300" alt="helper" />
			</div>
		</div>
		<div class="p-col-12 p-md-6">
			<div class="card">
				<h5 style="margin-left: 10px;">使用反馈</h5>
				<p style="margin-left: 10px;">有任何使用问题，改进建议都可发送邮件至 <Tag class="p-mr-2" value="Primary"> libertynlp@163.com </Tag>我们将会认真对待，感谢支持！</p>
			</div>
		</div>
	</div>
</template>
<script>
	export default {
		data() {
			return {
				
			}
		},
		majorService: null,
		created() {

		},
		components: {

		},
		mounted() {

		},
		computed:{

		},
		methods: {
			
		}
	}
</script>
<style scoped lang="scss">
	.down-img-button{
		// color: var(--primary-color);
		color: #FFFFFF;
		padding: 5px;
		border-radius: 3px;
		background-color:var(--primary-color) ;
		margin-left: 20px;
		font-weight: 800;
		transform: rotate(90deg);
	}
</style>
